import { Swiper, SwiperSlide } from "swiper/react";

import { Autoplay } from "swiper/modules";

import { Projects as projects } from "../pages/productlist/projects";

import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import "swiper/css";
import { ScrollToTop } from "../../utils/utils";

function Projects() {
  let projs = [];

  const color = ["lime-800", "red-800", "gray-900"];

  projects.items.forEach((p, i) => {
    if (i < 6) {
      const cid = (i + 1) % 3;
      projs.push({
        img: p.img[0],
        color: color[cid],
        id: p.id,
        title: p.title,
        place: p.category,
      });
    }
  });

  return (
    <div
      className='mt-16 p-8 mx-4'
      data-aos='zoom-in'
      data-aos-offset='300'
      data-aos-duration='1000'
    >
      <div className='font-bold text-3xl p-4 mb-4 text-slate-700'>
        Fetured Projects
      </div>
      <span className='bg-gray-900'></span>
      <span className='bg-red-800'></span>
      <span className='bg-lime-800 '></span>
      <Swiper
        lazyPreloadPrevNext={1}
        modules={[Autoplay]}
        autoplay={{
          delay: 10000,
          stopOnLastSlide: false,
          pauseOnMouseEnter: true,
        }}
        breakpoints={{
          680: {
            slidesPerView: 2,
          },
          1024: {
            slidesPerView: 2,
          },
          1280: {
            slidesPerView: 3,
          },
          1536: {
            slidesPerView: 4,
          },
        }}
        centeredSlides={true}
        loop={true}
      >
        {projs.map((proj, id) => (
          <SwiperSlide key={id}>
            <Project proj={proj} id={id} key={id} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}

function Project({ proj, id }) {
  return (
    <Link
      to={`projects/${proj.id}`}
      className='flex flex-col items-center justify-center p-4 pt-20'
      onClick={ScrollToTop}
    >
      <img
        loading='lazy'
        src={proj.img}
        alt='Product'
        className='object-fill rounded-full h-60 w-60 border-4 p-2 bg-sky-400 border-dashed  border-red-400 animate-bounce'
      />
      <div className='text-center pt-4 text-lg text-slate-50'>{proj.title}</div>
    </Link>
  );
}

function ProjectDetails({ id, proj }) {
  const [showDiv, setShowDiv] = useState(true);

  const containerRef = useRef("container");
  const holdingRef = useRef("container");

  useEffect(() => {
    const fallAnime = setTimeout(() => {
      animate();
      setShowDiv(!showDiv);
    }, 3000); // 5 seconds
    return () => {
      clearTimeout(fallAnime);
    };
  }, [showDiv]);

  const animate = () => {
    containerRef.current.classList.toggle("invisible");
    holdingRef.current.classList.toggle("h-80");
  };

  return (
    <div
      className='invisible h-full absolute inset-0 rounded-full'
      style={{ width: "inherit" }}
      ref={containerRef}
      key={id}
    >
      <div
        ref={holdingRef}
        className={`m-4 h-2 overflow-hidden duration-1000 ease-in-out transition-all inset-0 absolute bg-${proj.color} opacity-80 rounded-full text-white grid grid-rows gap-4`}
      >
        <div className='row-span-1 grid place-items-center font-bold text-xl pt-4 px-8 line-clamp-1 text-center'>
          {proj.place}
        </div>
        <div className='row-span-1 font-semibold text-3xl px-8 text-center'>
          {proj.title}
        </div>
        <div className='row-span-1 overflow-hidden pb-6 px-8 text-center'>
          <span>&#10148;</span>
          View More
        </div>
      </div>
    </div>
  );
}

export default Projects;
