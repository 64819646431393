import TypeWriterEffect from "react-typewriter-effect";
import { TbTargetArrow } from "react-icons/tb";
import { IoTelescopeSharp } from "react-icons/io5";

function About() {
  document.title = `Krico | About`;
  return (
    <div className='p-4 text-slate-800 leading-8'>
      <div className=' mb-8 '>
        <h1 className='my-8 text-4xl font-extrabold leading-none tracking-tight uppercase text-red-800 md:text-5xl lg:text-6xl grid place-items-center'>
          About
        </h1>
        <div className='mb-10 text-lg font-semibold lg:text-2xl sm:px-16 xl:px-48 grid place-items-center'>
          KRICO PANEL GLOBAL PRIVATE LIMITED
        </div>
      </div>
      <div className='grid grid-cols-1 gap-6 lg:grid-cols-2'>
        <div className='mb-8 clay p-4 col-s bg-green-100'>
          <div className='flex items-center place-content-center text-3xl font-extrabold my-6 text-green-700'>
            <TbTargetArrow className='scale-150 mx-4 ' />
            Mission
          </div>
          <div className='indent-16 p-4'>
            <TypeWriterEffect
              startDelay={100}
              typeSpeed={1}
              hideCursorAfterText
              cursorColor='black'
              text='Krico panel Global Private Limited could be to deliver reliable, customizable, and energy-efficient electrical panels that exceed industry standards. By fostering a culture of continuous improvement, sustainability, and client collaboration, Krico aims to be a leading provider in the electrical manufacturing sector, ensuring customer satisfaction and contributing to a resilient and connected future.'
            />
          </div>
        </div>
        <div className='mb-8 clay p-4 col-s bg-blue-100'>
          <div className='flex items-center place-content-center text-3xl font-extrabold my-6 text-blue-500 hover:text-green-500'>
            <IoTelescopeSharp className='scale-150 mx-4 ' />
            Vision
          </div>
          <div className='indent-16 p-4'>
            <TypeWriterEffect
              startDelay={100}
              typeSpeed={1}
              hideCursorAfterText
              cursorColor='black'
              text='Krico panel Global Private Limited could focus on innovation, sustainability, and efficiency in producing cutting-edge electrical panels. Prioritizing quality, safety, and staying at the forefront of technological advancements can be key elements of this vision.'
            />
          </div>
        </div>
      </div>
      <div className='text-slate-700 bg-clip-padding backdrop-filter backdrop-blur-xl bg-opacity-50 p-12'>
        <div className='mb-8 indent-16 first-letter:text-2xl first-letter:font-extrabold first-letter:tracking-widest'>
          <TypeWriterEffect
            startDelay={100}
            typeSpeed={1}
            hideCursorAfterText
            cursorColor='black'
            text='Krico Panel is a leading company specializing in the manufacturing of electrical HV (High Voltage), LV (Low Voltage), and MV (Medium Voltage) control panels. With a strong focus on quality, innovation, and customer satisfaction, Krico Panel has established itself as a trusted name in the industry.'
          />
        </div>
        <div className='mb-8 indent-16 first-letter:text-2xl first-letter:font-extrabold first-letter:tracking-widest'>
          <TypeWriterEffect
            startDelay={100}
            typeSpeed={1}
            hideCursorAfterText
            cursorColor='black'
            text='The company&#39;s expertise lies in designing and producing control panels that meet the specific requirements of various industries, including power generation, oil and gas, manufacturing, and infrastructure. Krico Panel understands the critical role that control panels play in ensuring the safe and efficient operation of electrical systems, and therefore, they prioritize the highest standards of quality and reliability in their products.'
          />
        </div>
        <div className='mb-8 indent-16 first-letter:text-2xl first-letter:font-extrabold first-letter:tracking-widest'>
          <TypeWriterEffect
            startDelay={100}
            typeSpeed={1}
            hideCursorAfterText
            cursorColor='black'
            text='Krico Panel&#39;s HV control panels are designed to handle high-voltage power distribution and control. These panels are equipped with advanced features such as circuit breakers, transformers, and protective relays to ensure the safe and reliable transmission of electricity. The company&#39;s LV control panels are designed for low-voltage applications and are widely used in commercial and industrial settings. These panels are built to withstand heavy loads and provide precise control over electrical systems.'
          />
        </div>
        <div className='grid grid-cols-1 gap-6 lg:grid-cols-3 '>
          <div className='mb-8 col-s indent-16 first-letter:text-2xl first-letter:font-extrabold first-letter:tracking-widest'>
            <TypeWriterEffect
              startDelay={100}
              typeSpeed={1}
              hideCursorAfterText
              cursorColor='black'
              text='In addition to HV and LV control panels, Krico Panel also specializes in manufacturing MV control panels. These panels are designed to handle medium voltage power distribution and control, typically ranging from 11kV to 36kV. They are engineered to meet the specific requirements of medium voltage applications, ensuring the safe and efficient operation of electrical systems in industries such as power plants, substations, and large-scale manufacturing facilities.'
            />
          </div>

          <div className='mb-8 col-span-2'>
            <iframe
              title='Krico'
              className='w-full h-80 lg:h-full'
              loading='lazy'
              src='https://www.youtube.com/embed/zTMFEOBs7vE'
              frameborder='0'
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
              allowfullscreen=''
            ></iframe>
          </div>
        </div>
        <div className='mb-8 indent-16 first-letter:text-2xl first-letter:font-extrabold first-letter:tracking-widest'>
          <TypeWriterEffect
            startDelay={100}
            typeSpeed={1}
            hideCursorAfterText
            cursorColor='black'
            text='Krico Panel&#39;s commitment to quality is reflected in its state-of-the-art manufacturing facilities and rigorous quality control processes. The company employs a team of highly skilled engineers and technicians who work closely with clients to understand their unique requirements and deliver customized solutions. Krico Panel also keeps up with the latest technological advancements in the industry to ensure that their control panels are equipped with the most advanced features and functionalities.'
          />
        </div>
        <div className='mb-8 indent-16 first-letter:text-2xl first-letter:font-extrabold first-letter:tracking-widest'>
          <TypeWriterEffect
            startDelay={100}
            typeSpeed={1}
            hideCursorAfterText
            cursorColor='black'
            text='Krico Panel is a reputable company specializing in the manufacturing of electrical HV, LV, and MV control panels. With their focus on quality, innovation, and customer satisfaction, they have established themselves as a trusted provider of reliable and efficient control panel solutions for various industries.'
          />
        </div>
      </div>
    </div>
  );
}

export default About;
