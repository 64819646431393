export const Projects = {
  title: "Projects",
  description: [],
  items: [
    {
      id: 1,
      img: [
        "https://www.rkeinfra.com/wp-content/uploads/2020/08/rsz_textile1.jpg",
        "https://www.rkeinfra.com/wp-content/uploads/2020/08/rsz_textile2.jpg",
        "https://www.rkeinfra.com/wp-content/uploads/2020/08/rsz_textile3.jpg",
      ],
      description: [],
      category: "IND",
      title: "Textile Processing",
    },
    {
      id: 2,
      img: [
        "https://www.rkeinfra.com/wp-content/uploads/2020/08/1578194632596.jpg",
        "https://www.rkeinfra.com/wp-content/uploads/2020/08/IMG_20190328_175422.jpg",
        "https://www.rkeinfra.com/wp-content/uploads/2020/08/WhatsApp-Image-2020-03-12-at-5.28.14-PM.jpeg",
        "https://www.rkeinfra.com/wp-content/uploads/2020/10/03.jpg",
        "https://www.rkeinfra.com/wp-content/uploads/2020/10/04.jpg",
      ],
      description: [],
      category: "IND",
      title: "Metal Industry",
    },
    {
      id: 3,
      img: [
        "https://www.rkeinfra.com/wp-content/uploads/2020/08/rsz_hpanel1.jpg",
        "https://www.rkeinfra.com/wp-content/uploads/2020/08/rsz_hpanel2.jpg",
        "https://www.rkeinfra.com/wp-content/uploads/2020/08/rsz_hpanel3.jpg",
        "https://www.rkeinfra.com/wp-content/uploads/2020/08/rsz_hpanel5.jpg",
        "https://www.rkeinfra.com/wp-content/uploads/2020/08/rsz_img_20180424_123017-1.jpg",
        "https://www.rkeinfra.com/wp-content/uploads/2020/08/WhatsApp-Image-2020-03-12-at-5.28.04-PM.jpeg",
      ],
      description: [],
      category: "IND",
      title: "Health Care",
    },
    {
      id: 4,
      img: [
        "https://www.rkeinfra.com/wp-content/uploads/2020/08/1532011482265.jpg",
        "https://www.rkeinfra.com/wp-content/uploads/2020/08/1532011482376.jpg",
        "https://www.rkeinfra.com/wp-content/uploads/2020/08/1532011486278.jpg",
        "https://www.rkeinfra.com/wp-content/uploads/2020/08/1532011486366.jpg",
        "https://www.rkeinfra.com/wp-content/uploads/2020/08/1532011486713.jpg",
        "https://www.rkeinfra.com/wp-content/uploads/2020/08/1532011487282.jpg",
        "https://www.rkeinfra.com/wp-content/uploads/2020/08/1532011487955.jpg",
        "https://www.rkeinfra.com/wp-content/uploads/2020/10/06.jpg",
        "https://www.rkeinfra.com/wp-content/uploads/2020/10/05.jpg",
      ],
      description: [],
      category: "IND",
      title: "Granite",
    },
    {
      id: 5,
      img: [
        "https://www.rkeinfra.com/wp-content/uploads/2020/08/rsz_1img_20180425_174244.jpg",
        "https://www.rkeinfra.com/wp-content/uploads/2020/08/rsz_img_20180425_174114.jpg",
        "https://www.rkeinfra.com/wp-content/uploads/2020/08/rsz_img_20180425_174132.jpg",
        "https://www.rkeinfra.com/wp-content/uploads/2020/08/rsz_img_20180425_174152.jpg",
        "https://www.rkeinfra.com/wp-content/uploads/2020/08/rsz_img_20180425_174221.jpg",
        "https://www.rkeinfra.com/wp-content/uploads/2020/08/rsz_img_20180425_174231.jpg",
        "https://www.rkeinfra.com/wp-content/uploads/2020/08/rsz_img_20180425_174512.jpg",
        "https://www.rkeinfra.com/wp-content/uploads/2020/08/rsz_img_20180425_174607.jpg",
      ],
      description: [],
      category: "IND",
      title: "Glass Industry",
    },
    {
      id: 6,
      img: [
        "https://www.rkeinfra.com/wp-content/uploads/2020/08/1578193646190.jpg",
        "https://www.rkeinfra.com/wp-content/uploads/2020/08/1578193646243.jpg",
        "https://www.rkeinfra.com/wp-content/uploads/2020/08/1578193646714.jpg",
        "https://www.rkeinfra.com/wp-content/uploads/2020/08/1578193646789.jpg",
        "https://www.rkeinfra.com/wp-content/uploads/2020/08/1578193647630.jpg",
        "https://www.rkeinfra.com/wp-content/uploads/2020/08/1578194576540.jpg",
        "https://www.rkeinfra.com/wp-content/uploads/2020/08/1578194577264.jpg",
        "https://www.rkeinfra.com/wp-content/uploads/2020/08/1578194577333.jpg",
        "https://www.rkeinfra.com/wp-content/uploads/2020/08/PHOTO-2020-06-19-13-01-39.jpg",
      ],
      description: [],
      category: "IND",
      title: "Garments",
    },
    {
      id: 7,
      img: [
        "https://www.rkeinfra.com/wp-content/uploads/2020/08/1578193649069.jpg",
        "https://www.rkeinfra.com/wp-content/uploads/2020/08/1578193649555.jpg",
        "https://www.rkeinfra.com/wp-content/uploads/2020/08/1578193652629.jpg",
        "https://www.rkeinfra.com/wp-content/uploads/2020/08/1578193652679.jpg",
        "https://www.rkeinfra.com/wp-content/uploads/2020/08/1578193653005.jpg",
        "https://www.rkeinfra.com/wp-content/uploads/2020/08/1578193653262.jpg",
        "https://www.rkeinfra.com/wp-content/uploads/2020/08/1578193653432.jpg",
        "https://www.rkeinfra.com/wp-content/uploads/2020/08/1578193653522.jpg",
      ],
      description: [],
      category: "IND",
      title: "Food Industry",
    },
    {
      id: 8,
      img: [
        "https://www.rkeinfra.com/wp-content/uploads/2020/08/1532011483435.jpg",
        "https://www.rkeinfra.com/wp-content/uploads/2020/08/1532011483479.jpg",
        "https://www.rkeinfra.com/wp-content/uploads/2020/10/01.jpg",
        "https://www.rkeinfra.com/wp-content/uploads/2020/10/02.jpg",
      ],
      category: "IND",
      description: [],
      title: "Dyeing Industry",
    },
    {
      id: 9,
      img: [
        "https://www.rkeinfra.com/wp-content/uploads/2020/08/1527853542683.jpg",
        "https://www.rkeinfra.com/wp-content/uploads/2020/08/1527853547317.jpg",
        "https://www.rkeinfra.com/wp-content/uploads/2020/08/1527853547356.jpg",
        "https://www.rkeinfra.com/wp-content/uploads/2020/08/1527853547519.jpg",
        "https://www.rkeinfra.com/wp-content/uploads/2020/08/1527853547750.jpg",
        "https://www.rkeinfra.com/wp-content/uploads/2020/08/WhatsApp-Image-2020-03-12-at-5.28.08-PM-1.jpeg",
      ],
      description: [],
      category: "IND",
      title: "Drinking Water Project",
    },
  ],
};
