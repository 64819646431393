import { Link } from "react-router-dom";
import icon from "../../img/icon.png";
import { useEffect, useRef } from "react";
import { ScrollToTop } from "../../utils/utils";
import { IoMenu } from "react-icons/io5";

const menuItems = [
  { text: "Home", link: "/" },
  { text: "About", link: "/about" },
  {
    text: "Products",
    link: "#",
    submenu: [
      {
        text: "High Voltage Panel",
        link: "/products/hv",
        submenu: [
          { text: "Indoor VCB", link: "/product/hv/1" },
          { text: "Outdoor VCB", link: "/product/hv/2" },
          { text: "Load Break Switch", link: "/product/hv/3" },
          { text: "Ring Main Unit", link: "/product/hv/4" },
          { text: "Compact Substation", link: "/product/hv/5" },
        ],
      },
      {
        text: "Medium Voltage Panel",
        link: "/products/mv",
        submenu: [
          { text: "Air Circuit Breaker Panel", link: "/product/mv/6" },
          { text: "Main Voltage Panel", link: "/product/mv/7" },
          { text: "Sub Switch Board", link: "/product/mv/8" },
          { text: "Automatic Power Factor Control", link: "/product/mv/9" },
          { text: "Motor Control Center Panel", link: "/product/mv/10" },
          { text: "PLC – SCADA Panel", link: "11" },
        ],
      },
      {
        text: "Low Voltage Panel",
        link: "/products/lv",
        submenu: [
          { text: "LTCT Meter Box", link: "/product/lv/12" },
          { text: "EB Meter Box Busbar", link: "/product/lv/13" },
          { text: "VTPN Distribution Boards", link: "/product/lv/14" },
          { text: "MCB Distribution Boards", link: "/product/lv/15" },
          { text: "Lighting Distribution Panel", link: "/product/lv/16" },
        ],
      },
    ],
  },
  {
    text: "Service",
    link: "#",
    submenu: [
      { text: "Electrical Consultancy", link: "/service/consultancy" },
      {
        text: "Electrical Engineering Contractors",
        link: "/service/contractors",
      },
      { text: "Maintenance", link: "/service/maintenance" },
    ],
  },
  { text: "Infrastructure", link: "/infrastructure" },
  { text: "Projects", link: "/projects" },
  { text: "Contact", link: "/contact" },
];

const displayMobileMenu = () => {
  document.getElementById("mobile-menu").classList.toggle("hidden");
  const isSmall = document
    .getElementById("mobile-menu")
    .classList.contains("hidden");

  const classList = ["h-16", "sm:h-20", "border-b-2"];
  const iconClassList = ["scale-75"];
  if (window.scrollY > 64) {
    if (isSmall) {
      classList.map((cls) => document.getElementById("nav").classList.add(cls));
      iconClassList.map((cls) =>
        document.getElementById("icon").classList.add(cls)
      );
    } else {
      classList.map((cls) =>
        document.getElementById("nav").classList.remove(cls)
      );
      iconClassList.map((cls) =>
        document.getElementById("icon").classList.remove(cls)
      );
    }
  }
};

const NavBar = () => {
  return (
    <>
      {/* <!-- navbar goes here --> */}
      <nav id='menu'>
        <div className='px-4 lg:px-12 '>
          <div className='flex justify-between'>
            <div className='flex space-x-8'>
              {/* <!-- logo --> */}
              <div>
                <Link
                  to='/'
                  className='flex items-center py-2 px-2'
                  onClick={ScrollToTop}
                >
                  <img
                    src={icon}
                    className='max-w-xs md:max-w-sm m-auto h-16 md:h-24 transition-all duration-300 ease-linear'
                    alt='logo'
                    id='icon'
                  />
                </Link>
              </div>

              <DestopView />
            </div>
            <div className='lg:hidden flex items-center'>
              <button
                id='mobile-menu-button'
                onClick={() => {
                  displayMobileMenu();
                }}
              >
                <IoMenu className='h-7 w-7' />
              </button>
            </div>
          </div>
        </div>

        {/* <!-- mobile menu --> */}
        <div
          className='hidden lg:hidden h-screen transition-all ease-linear duration-1000 bg-slate-100 overflow-scroll'
          id='mobile-menu'
        >
          <MobileMenuItems />
        </div>
      </nav>
    </>
  );
};

const MobileMenuItems = () => {
  const BuildMenu = (menuItems) => {
    const BuildSubMenu = ({ item, key }) => {
      const ref = useRef();
      return (
        <div className='block py-2'>
          <Link to={item.link} onClick={ScrollToTop}>
            <div onClick={() => ref.current.classList.toggle("hidden")}>
              {item.text}
              <span className='text-xl font-extrabold text-lime-500 '>
                &#8675;
              </span>
            </div>
          </Link>
          <div className='hidden p-4' ref={ref}>
            {BuildMenu(item.submenu)}
          </div>
        </div>
      );
    };
    return menuItems.map((item, index) => {
      if (!item.submenu) {
        return (
          <Link
            to={item.link}
            className='block py-2'
            key={`${item.text}-${index}`}
            onClick={() => {
              ScrollToTop();
              displayMobileMenu();
            }}
          >
            {item.text}
          </Link>
        );
      } else {
        return (
          <div key={`${item.text}-${index}`}>
            <BuildSubMenu item={item} />
          </div>
        );
      }
    });
  };
  return (
    <div className='h-full flex flex-col text-xl pl-8 pt-8' key={"mobile"}>
      {BuildMenu(menuItems)}
    </div>
  );
};

const DestopView = () => {
  const BuildMenu = (menuItems) => {
    const BuildSubMenu = ({ item, key }) => {
      const ref = useRef();
      return (
        <div
          className='flex relative cursor-pointer'
          key={key}
          onMouseOver={() => ref.current.classList.remove("hidden")}
          onMouseOut={() => ref.current.classList.add("hidden")}
        >
          <Link to={item.link} onClick={ScrollToTop}>
            <div className='w-full block px-4 py-2 whitespace-nowrap hover:bg-slate-100'>
              {item.text}
              <span className='text-xl font-extrabold text-red-700 '>
                &#8675;
              </span>
            </div>
          </Link>
          <div
            className='hidden absolute border-2 border-slate-100 bg-sky-400 left-full top-0 min-w-fit z-30'
            ref={ref}
          >
            {BuildMenu(item.submenu)}
          </div>
        </div>
      );
    };
    return menuItems.map((item, index) => {
      if (!item.submenu) {
        return (
          <div
            className='w-full whitespace-nowrap hover:bg-slate-100'
            key={`${item.text}-${index}`}
          >
            <Link
              to={item.link}
              className='block py-2 px-4'
              onClick={ScrollToTop}
            >
              {item.text}
            </Link>
          </div>
        );
      } else {
        return (
          <div key={`${item.text}-${index}`}>
            <BuildSubMenu item={item} />
          </div>
        );
      }
    });
  };
  return (
    <div
      className='hidden lg:flex items-center space-x-1 text-slate-700'
      key={"desktop"}
    >
      {BuildMenu(menuItems)}
    </div>
  );
};

function NavigationBar() {
  useEffect(() => {
    const scroller = () => {
      const shouldAnimate = document
        .getElementById("mobile-menu")
        .classList.contains("hidden");
      const classList = ["h-16", "sm:h-20", "border-b-2"];
      const iconClassList = ["scale-75"];
      if (shouldAnimate) {
        if (window.scrollY > 64) {
          classList.map((cls) =>
            document.getElementById("nav").classList.add(cls)
          );
          iconClassList.map((cls) =>
            document.getElementById("icon").classList.add(cls)
          );
        } else {
          classList.map((cls) =>
            document.getElementById("nav").classList.remove(cls)
          );
          iconClassList.map((cls) =>
            document.getElementById("icon").classList.remove(cls)
          );
        }
      }
    };
    window.addEventListener("scroll", scroller);
    return () => window.removeEventListener("scroll", scroller);
  }, []);
  return (
    <>
      <header
        id='nav'
        className='items-center z-50 transition-all duration-500 ease-linear fixed w-full bg-sky-400'
      >
        <NavBar />
      </header>
    </>
  );
}

export default NavigationBar;
