export const HVolt = [
  {
    id: 1,
    img: [
      "https://www.rkeinfra.com/wp-content/uploads/2020/08/03-Load-Break-Switch-286x300.jpg",
    ],
    description: [],
    category: "hv",
    title: "Load Break Switch",
    spec: [
      { title: "Rating", value: "26kV, 800A, 26.3KA can withstand 3 Sec.50Hz" },
      {
        title: "Rated Lightning Impulse Voltage",
        value: "12/28/75kV Peak",
      },
      {
        title: "Degree of protection",
        value: "IP 4x / 5x",
      },
      {
        title: "Applicable standard",
        value: "IEC 62271-105 / 200",
      },
    ],
  },
  {
    id: 2,
    img: [
      "https://www.rkeinfra.com/wp-content/uploads/2020/08/01-Indoor-VCB-286x300.jpg",
    ],
    description: [],
    category: "hv",
    title: "Indoor VCB",
    spec: [
      { title: "Types", value: "PLC Operating System" },
      {
        title: "Rating",
        value: "12kV to 36kV, upto 1200A, 26.3KA can withstand 3 Sec.50Hz",
      },
      {
        title: "Rated Lightning Impulse Voltage",
        value: "12/28/75kV Peak",
      },
      {
        title: "Degree of protection",
        value: "IP 4x / 5x",
      },
      {
        title: "Applicable standard",
        value: "IEC 62271-105 / 200",
      },
    ],
  },
  {
    id: 3,
    img: ["https://www.rkeinfra.com/wp-content/uploads/2020/08/lvbs1.png"],
    description: [],
    category: "hv",
    title: "Load Break Switch",
    spec: [
      {
        title: "Rating",
        value: "26kV, 800A, 26.3KA can withstand 3 Sec.50Hz",
      },

      {
        title: "Rated Lightning Impulse Voltage",
        value: "12/28/75kV Peak",
      },
      {
        title: "Degree of protection ",
        value: "IP 4x / 5x",
      },
      {
        title: "Applicable standard",
        value: "IEC 62271-105 / 200",
      },
    ],
  },
  {
    id: 4,
    category: "hv",
    img: ["https://www.rkeinfra.com/wp-content/uploads/2020/08/ring.png"],
    description: [
      "SF6 gas insulation & current interruption – No fire hazard",
      "Maintenance free & sealed for life contact system",
      "Outdoor installation",
      "Robust construction",
      "Tee-off circuit breaker with integral protection system",
      "Low cost cable termination",
      "Fully extendable options",
      "Motorized mechanism",
    ],
    title: "Ring Main Unit",
    spec: [],
  },
  {
    id: 5,
    img: ["https://www.rkeinfra.com/wp-content/uploads/2020/08/com.png"],
    description: [],
    category: "hv",
    title: "Compact Substation",
    spec: [
      { title: "Rated current  in HV Side", value: "11kV LBS / VCB" },
      {
        title: "Rated  current in Transformer side Transformer",
        value: "Upto2500KVA 11kV / 433VOCTC /OLTC, Dry / Oil cooled",
      },
      {
        title: "Rated  current in LV Side",
        value: "ACB, MCCB,APFC, DB,Sub Pane",
      },
      {
        title: "Applicable standard",
        value: "IEC 62271 – 202, IEC 1330(1995) IS 14786-2000",
      },
    ],
  },
];

export const MVolt = [
  {
    id: 6,
    img: ["https://www.rkeinfra.com/wp-content/uploads/2020/08/air.png"],
    description: [],
    category: "mv",
    title: "Air Circuit Breaker Panel",
    spec: [
      { title: "Types", value: "All types of Medium voltage distribution" },
      { title: "Rating", value: "63A to 6300A / 1100V" },
      { title: "System", value: "3 Phase 3 Wire or 4 Wire" },
      { title: "Operational Voltage", value: "440 V / 660 V" },
      { title: "Short Circuit Current", value: "36 KA / 50 KA for 1 Sec" },
      { title: "Degree of Production", value: "IP 42, IP 54, IP 55, IP 65" },
      { title: "Bus Bar", value: "Aluminum / Copper" },
      { title: "BIS Standard", value: "IEC 60204-1, 60439 – 1&2, IS: 8623" },
    ],
  },
  {
    id: 7,
    img: ["https://www.rkeinfra.com/wp-content/uploads/2020/08/mainv.png"],
    description: [],
    category: "mv",
    title: "Main Voltage Panel",
    spec: [
      { title: "Types", value: "All types of Medium voltage distribution" },
      { title: "Rating", value: "63A to 6300A / 1100V" },
      { title: "System", value: "3 Phase 3 Wire or 4 Wire" },
      { title: "Operational Voltage", value: "440 V / 660 V" },
      { title: "Short Circuit Current", value: "36 KA / 50 KA for 1 Sec" },
      { title: "Degree of Production", value: "IP 42, IP 54, IP 55, IP 65" },
      { title: "Bus Bar", value: "Aluminum / Copper" },
      { title: "BIS Standard", value: "IEC 60204-1, 60439 – 1&2, IS: 8623" },
    ],
  },
  {
    id: 8,
    img: ["https://www.rkeinfra.com/wp-content/uploads/2020/08/sub.png"],
    description: [],
    category: "mv",
    title: "Sub Switch Board",
    spec: [
      { title: "Types", value: "All types of Medium voltage distribution" },
      { title: "Rating", value: "63A to 6300A / 1100V" },
      { title: "System", value: "3 Phase 3 Wire or 4 Wire" },
      { title: "Operational Voltage", value: "440 V / 660 V" },
      { title: "Short Circuit Current", value: "36 KA / 50 KA for 1 Sec" },
      { title: "Degree of Production", value: "IP 42, IP 54, IP 55, IP 65" },
      { title: "Bus Bar", value: "Aluminum / Copper" },
      { title: "BIS Standard", value: "IEC 60204-1, 60439 – 1&2, IS: 8623" },
    ],
  },
  {
    id: 9,
    img: ["https://www.rkeinfra.com/wp-content/uploads/2020/08/apf.png"],
    description: [],
    category: "mv",
    title: "Automatic Power Factor Control",
    spec: [
      {
        title: "Types",
        value:
          "with Harmonic filter, without harmonic filter. Improving the network power factor",
      },
      { title: "Rating", value: "63A to 6300A / 1100V" },
      { title: "System", value: "3 Phase 3 Wire or 4 Wire" },
      { title: "Operational Voltage", value: "440 V / 660 V" },
      { title: "Short Circuit Current", value: "36 KA / 50 KA for 1 Sec" },
      { title: "Degree of Production", value: "IP 42, IP 54, IP 55, IP 65" },
      { title: "Bus Bar", value: "Aluminum / Copper" },
      { title: "BIS Standard", value: "IEC 60204-1, 60439 – 1&2, IS: 8623" },
    ],
  },
  {
    id: 10,
    img: ["https://www.rkeinfra.com/wp-content/uploads/2020/08/moc.png"],
    description: [],
    category: "mv",
    title: "Motor Control Center Panel",
    spec: [
      {
        title: "Types",
        value:
          "All type of motor control, VFD Starter, Soft strter panels, Motor products cooler",
      },
      { title: "Rating", value: "63A to 6300A / 1100V" },
      { title: "System", value: "3 Phase 3 Wire or 4 Wire" },
      { title: "Operational Voltage", value: "440 V / 660 V" },
      { title: "Short Circuit Current", value: "36 KA / 50 KA for 1 Sec" },
      { title: "Degree of Production", value: "IP 42, IP 54, IP 55, IP 65" },
      { title: "Bus Bar", value: "Aluminum / Copper" },
      { title: "BIS Standard", value: "IEC 60204-1, 60439 – 1&2, IS: 8623" },
    ],
  },
  {
    id: 11,
    img: ["https://www.rkeinfra.com/wp-content/uploads/2020/08/plc.png"],
    description: [],
    category: "mv",
    title: "PLC – SCADA Panel",
    spec: [
      { title: "Types", value: "All type of automatic PLC Control panel" },
      { title: "Rating", value: "63A to 6300A / 1100V" },
      { title: "System", value: "3 Phase 3 Wire or 4 Wire" },
      { title: "Operational Voltage", value: "440 V / 660 V" },
      { title: "Short Circuit Current", value: "36 KA / 50 KA for 1 Sec" },
      { title: "Degree of Production", value: "IP 42, IP 54, IP 55, IP 65" },
      { title: "Bus Bar", value: "Aluminum / Copper" },
      { title: "BIS Standard", value: "IEC 60204-1, 60439 – 1&2, IS: 8623" },
    ],
  },
];

export const LVolt = [
  {
    id: 12,
    img: ["https://www.rkeinfra.com/wp-content/uploads/2020/08/ltct.png"],
    description: [],
    category: "lv",
    title: "LTCT Meter Box",
    spec: [
      { title: "Types", value: "150kV" },
      { title: "Rating", value: "63A to 6300A / 1100V" },
      { title: "System", value: "3 Phase 3 Wire or 4 Wire" },
      { title: "Operational Voltage", value: "440 V / 660 V" },
      { title: "Short Circuit Current", value: "36 KA / 50 KA for 1 Sec" },
      { title: "Degree of Production", value: "IP 42, IP 54, IP 55, IP 65" },
      { title: "Bus Bar", value: "Aluminum / Copper" },
      { title: "BIS Standard", value: "IEC 60204-1, 60439 – 1&2, IS: 8623" },
    ],
  },
  {
    id: 13,
    img: ["https://www.rkeinfra.com/wp-content/uploads/2020/08/lve.png"],
    description: [],
    category: "lv",
    title: "EB Meter Box Busbar",
    spec: [
      { title: "Rating", value: "63A to 6300A / 1100V" },
      { title: "System", value: "3 Phase 3 Wire or 4 Wire" },
      { title: "Operational Voltage", value: "440 V / 660 V" },
      { title: "Short Circuit Current", value: "36 KA / 50 KA for 1 Sec" },
      { title: "Degree of Production", value: "IP 42, IP 54, IP 55, IP 65" },
      { title: "Bus Bar", value: "Aluminum / Copper" },
      { title: "BIS Standard", value: "IEC 60204-1, 60439 – 1&2, IS: 8623" },
    ],
  },
  {
    id: 14,
    img: ["https://www.rkeinfra.com/wp-content/uploads/2020/08/vtpn.png"],
    description: [],
    category: "lv",
    title: "VTPN Distribution Boards",
    spec: [
      { title: "Rating", value: "63A to 6300A / 1100V" },
      { title: "System", value: "3 Phase 3 Wire or 4 Wire" },
      { title: "Operational Voltage", value: "440 V / 660 V" },
      { title: "Short Circuit Current", value: "36 KA / 50 KA for 1 Sec" },
      { title: "Degree of Production", value: "IP 42, IP 54, IP 55, IP 65" },
      { title: "Bus Bar", value: "Aluminum / Copper" },
      { title: "BIS Standard", value: "IEC 60204-1, 60439 – 1&2, IS: 8623" },
    ],
  },
  {
    id: 15,
    img: ["https://www.rkeinfra.com/wp-content/uploads/2020/08/mcb.png"],
    description: [],
    category: "lv",
    title: "MCB Distribution Boards",
    spec: [
      { title: "Rating", value: "63A to 6300A / 1100V" },
      { title: "System", value: "3 Phase 3 Wire or 4 Wire" },
      { title: "Operational Voltage", value: "440 V / 660 V" },
      { title: "Short Circuit Current", value: "36 KA / 50 KA for 1 Sec" },
      { title: "Degree of Production", value: "IP 42, IP 54, IP 55, IP 65" },
      { title: "Bus Bar", value: "Aluminum / Copper" },
      { title: "BIS Standard", value: "IEC 60204-1, 60439 – 1&2, IS: 8623" },
    ],
  },
  {
    id: 16,
    img: ["https://www.rkeinfra.com/wp-content/uploads/2020/08/air.png"],
    description: [],
    category: "lv",
    title: "Lighting Distribution Panel",
    spec: [
      { title: "Rating", value: "63A to 6300A / 1100V" },
      { title: "System", value: "3 Phase 3 Wire or 4 Wire" },
      { title: "Operational Voltage", value: "440 V / 660 V" },
      { title: "Short Circuit Current", value: "36 KA / 50 KA for 1 Sec" },
      { title: "Degree of Production", value: "IP 42, IP 54, IP 55, IP 65" },
      { title: "Bus Bar", value: "Aluminum / Copper" },
      { title: "BIS Standard", value: "IEC 60204-1, 60439 – 1&2, IS: 8623" },
    ],
  },
];

export const Products = [
  {
    id: "hv",
    title: "High Voltage Panel",
    items: HVolt,
    range: "12-13KV/1250 A",
    description: [
      "High-voltage panels manage electricity above 38,000 volts, pivotal in grid stations, power plants, and large-scale industries. These enclosures regulate and distribute immense power through specialized components like circuit breakers, surge arresters, and transformers. They're engineered to handle exceedingly high voltage levels, ensuring safe transmission and distribution across extensive networks. Custom-designed for specific applications, these panels incorporate intricate safety measures, protective relays, and advanced monitoring systems to prevent faults and ensure system stability.",
      "High-voltage panels are critical in sustaining reliable electricity supply for cities and industries, playing an indispensable role in managing substantial power loads and maintaining grid integrity.",
    ],
    category: "",
  },
  {
    id: "mv",
    title: "Medium Voltage Panel",
    items: MVolt,
    range: "630-6300A/1100V",
    description: [
      "A medium-voltage panel is an enclosure managing electrical power between 600 to 38,000 volts, crucial in industrial and utility settings. It regulates high-energy transmission via components like switches, circuit breakers, and transformers. These panels are engineered to handle varying voltage levels within the medium range, ensuring safe and efficient power distribution. Custom-built for specific applications, they incorporate advanced protective measures to prevent electrical faults and ensure reliability. ",
      "Medium-voltage panels facilitate controlled energy flow across expansive systems, serving as pivotal elements in substations, power plants, and large-scale industrial operations, where they play a critical role in maintaining uninterrupted electricity supply and system stability.",
    ],
  },
  {
    id: "lv",
    title: "Low Voltage Panel",
    items: LVolt,
    range: "63-400A/660V",
    description: [
      "A low voltage control panel is an electrical enclosure that manages and distributes power at lower voltage levels, typically up to 600 volts. It houses various components like circuit breakers, relays, and switches, allowing centralized control of electrical systems in industrial, commercial, or residential settings. These panels ensure safety by regulating and directing power flow, safeguarding against overloads or short circuits. ",
      "They play a crucial role in automation, facilitating the operation of machinery, lighting, and other systems. Customizable and adaptable, these panels are designed for efficient monitoring, troubleshooting, and maintenance, serving as a core element in electrical infrastructure across diverse applications.",
    ],
  },
];
