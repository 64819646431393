import tex from "../../img/clients/texr.png";
import wipro from "../../img/clients/wipor.png";
import vari from "../../img/clients/varir.png";
import lotus from "../../img/clients/lotur.png";
import fr from "../../img/clients/frr.png";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay } from "swiper/modules";

function Clients() {
  return (
    <Swiper
      autoplay={{
        delay: 2000,
        disableOnInteraction: false,
      }}
      centeredSlides={true}
      modules={[Autoplay]}
      slidesPerView={1}
      loop={true}
      breakpoints={{
        1024: {
          slidesPerView: 3,
        },
        1536: {
          slidesPerView: 5,
        },
      }}
      className='w-full h-60 mt-24'
      data-aos='zoom-in'
      data-aos-duration='2000'
    >
      <SwiperSlide>
        <Client img={tex} />
      </SwiperSlide>
      <SwiperSlide>
        <Client img={wipro} />
      </SwiperSlide>
      <SwiperSlide>
        <Client img={vari} />
      </SwiperSlide>
      <SwiperSlide>
        <Client img={lotus} />
      </SwiperSlide>
      <SwiperSlide>
        <Client img={fr} />
      </SwiperSlide>
      <SwiperSlide>
        <Client img={vari} />
      </SwiperSlide>
    </Swiper>
  );
}

const Client = ({ img }) => {
  return (
    <div className='clay bg-yellow-300 flex-shrink-0 m-auto relative overflow-hidden max-w-xs clay w-60 lg:w-48 border-2 border-sky-400'>
      <div className='relative py-4 flex items-center justify-center'>
        <img className='relative w-40' src={img} alt='' />
      </div>
    </div>
  );
};

export default Clients;
