import { Link } from "react-router-dom";
import { IoShieldCheckmarkSharp } from "react-icons/io5";
import { ScrollToTop } from "../../utils/utils";
import { MdElectricBike } from "react-icons/md";

function Products() {
  const products = [
    {
      title: "Panel Boards",
      head: "High Voltage",
      link: "/products/hv",
      range: ["12", "KV", "13", "KV", "1250", "A"],
      desc: [
        "Load Break Switch",
        "Indoor VCB",
        "Outdoor VCB ",
        "Ring Main Unit",
        "Compact Substation",
      ],
      color: "bg-gradient-to-r from-orange-300 via-lime-300 to-yellow-300",
    },
    {
      title: "Panel Boards",
      head: "Medium Voltage",
      link: "/products/mv",
      range: ["630", "A", "6300", "A", "1100", "V"],
      desc: [
        "Air circuit Breaker",
        "PLC-SCADA Panel",
        "Motor Control Centre Panel",
        "Sub Switch Board",
        "Automatic Power factor control ",
        "Main voltage Panel",
      ],
      color: "bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500",
    },
    {
      title: "Panel Boards",
      head: "Low Voltage",
      link: "/products/lv",
      range: ["63", "A", "400", "A", "660", "V"],
      desc: [
        "Lighting Distribution Panel",
        "MCB Distribution Panel",
        "ACB panel",
        "EB Meter Box Busbar",
        "LTCT Meter Box",
      ],
      color: "bg-gradient-to-br from-indigo-500 via-purple-500 to-pink-500",
    },
  ];
  return (
    <section
      className='bg-slate-300 bg-clip-padding backdrop-filter backdrop-blur-xl bg-opacity-50 mt-8 border-2 border-sky-400 mx-4 rounded-2xl'
      data-aos='zoom-in-down'
    >
      <div className='mx-auto max-w-screen-xl py-16 px-6'>
        <div className='mx-auto max-w-screen-md text-center mb-8 lg:mb-12 text-gray-700'>
          <h2 className='mb-4 text-3xl tracking-tight font-extrabold '>
            Designed For Every Needs
          </h2>
          <div className='mb-5 sm:text-xl '>
            We focus on markets where technology, innovation, and capital can
            unlock long-term value.
          </div>
        </div>
        <div className='space-y-8 lg:grid lg:grid-cols-3 sm:gap-6 xl:gap-10 lg:space-y-0'>
          {products.map((product, index) => (
            <Product product={product} key={index} />
          ))}
        </div>
      </div>
    </section>
  );
}

function Product({ product }) {
  return (
    <div
      className='flex flex-col p-6 w-80 mx-auto text-center text-slate-600 rounded-lg xl:p-8 transition-all ease-in-out duration-300 hover:scale-110 hover:shadow-xl'
      data-aos='zoom-in'
      data-aos-duration='1000'
    >
      <h3 className='my-4 text-2xl font-semibold'>{product.head}</h3>
      <div className='flex justify-center items-baseline mb-8'>
        <span className='mr-2'>
          <span className='text-xl font-extrabold'>{product.range[0]}</span>
          <span>-</span>
          <span className='text-xl font-extrabold'>{product.range[2]}</span>
          <span>{product.range[3]}</span>
          <span className='mx-2'>/</span>
          <span className='text-xl font-extrabold'>{product.range[4]}</span>
          <span>{product.range[5]}</span>
        </span>
      </div>
      {/* <!-- List --> */}
      <div role='list' className='mb-8 space-y-4 text-left flex-1'>
        {/* <!-- Icon --> */}
        {product.desc.map((desc, index) => (
          <div className='flex items-center space-x-3' key={index}>
            <IoShieldCheckmarkSharp className=' w-5 h-5  text-green-500' />
            <span>{desc}</span>
          </div>
        ))}
      </div>
      <Link
        to={product.link}
        onClick={ScrollToTop}
        className='transition-all ease-linear duration-200 text-white bg-sky-500 hover:bg-red-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center'
      >
        View More
      </Link>
    </div>
  );
}

export default Products;
