function CPDESC() {
  return (
    <section
      className='bg-slate-50 bg-clip-padding backdrop-filter backdrop-blur-xl bg-opacity-50 mt-16 border-2 border-yellow-200 mx-4'
      data-aos='zoom-in-down'
    >
      <div className='mx-auto max-w-screen-xl py-16 px-6'>
        <div className='mx-auto max-w-screen-md text-center mb-8 lg:mb-12 text-gray-700'>
          <h2 className='mb-4 text-3xl tracking-tight font-extrabold '>
            What is a control panel?
          </h2>
          <p className='mb-2 text-base leading-9 tracking-wide'>
            Power control panels are enclosures with components such as circuit
            breakers, relays, meters, designed to monitor and distribute power
            to various applications Acting as centralized hubs these panels
            enable monitoring, automation and protection, essential for optimal
            operation and ensures that the power system operates properly under
            internal technical and commercial conditions. Power management plays
            an important role in industrial and commercial environments focusing
            on managing power systems. These panels increase productivity,
            reduce downtime, provide a centralized interface for operators to
            monitor and manage various components and promote safety With
            integrated features such as circuit protection and diagnostics,
            control panels enable respond quickly to faults or irregularities,
            prevent potential damage, maintain reliability and protect power
            systems in various applications.
          </p>
        </div>
      </div>
    </section>
  );
}

export default CPDESC;
