import { useParams } from "react-router-dom";
import { MdOutlineElectricBolt } from "react-icons/md";
import { TbSettingsCog } from "react-icons/tb";
import { MdOutlineSupervisorAccount } from "react-icons/md";
import { HiOutlineHomeModern } from "react-icons/hi2";

const services = [
  {
    id: "consultancy",
    icon: <HiOutlineHomeModern />,
    title: "Electrical Consultancy",
    description: [
      "RK ENGINEERING AND INFRASTRUCTURE is a company comprising of highly experienced engineers in the field of Electrical Design, Estimation, Tendering, Project Management and Co-ordination. Company provide consultancy in the field of Electrical Design, Estimation, Tendering, Project Management and Co-ordination.",
      "The firm has been designing all types of Electrical and networking system, and specializing in designing systems for many diverse and specialized applications such as Hotels, Shopping Malls, Corporate offices, all type of industrials and Hospitals Sectors etc. Our endeavor has always been to design systems which are innovative yet economical. A special emphasis is also given on designing energy efficient systems using the latest technology.",
    ],
    quote: {
      show: true,
      item: [
        {
          title: "",
          desc: [""],
          points: [
            "Detailed analysis of client’s needs, project requirements.",
            "Study of site conditions and drawings.",
            "Coordination with architect to freeze preliminary design, provision of space requirement etc. for Electrical System.",
            "Provide assistance to client in evaluating the best alternative technically and economically to meet needs for present and future.",
            "To estimate the Load requirements for selection of Sub Station Equipments and DG Sets.",
            "Selection of suitable / economical Electrical Distribution System.",
            "Selection of Equipment.",
            "Estimation of space required for Electrical System such as HV / Main LT Panel Room, floor Elect, Panel Rooms, Cable routings etc.",
          ],
        },
      ],
    },
  },
  {
    id: "contractors",
    icon: <MdOutlineSupervisorAccount />,
    title: "Electrical Engineering Contractors",
    description: [
      "RK ENGINEERING AND INFRASTRUCTURE is a Electrical Contractor Service Company. We are proud of the reputation we have built for being a reliable and trustworthy electrical contractor, offering quality with affordability. With an extensive range of projects covering the Retail, commercial and industrial sectors, we boast a wealth of experience that we are able to apply to projects regardless of size or end use.",
    ],
    quote: {
      show: true,
      item: [
        {
          title: "A Skilled and Certified Workforce",
          desc: [
            "Our team consists of experienced electricians, who are trained to the latest industry standards, guaranteeing a quality service.",
            "We are an CEIG registered and approved contractor, and are Trustmark and Part EA certified contractor for HV / MV Installation work up to 33 KV Level.",
          ],
          points: [],
        },
        {
          title: "",
          desc: [
            "Recognized as one of leading electrical contractors, RKE Infra Group delivers high quality construction and maintenance outcomes for heavy industrial projects and power generation sectors.",
          ],
          points: [
            "CEIG Licensing Work (Electrical Drawing and Completion Report Certification)",
            "11, 22, 33 KV Sub Station Work",
            "High Voltage Distribution Work",
            "Transformer Erection up to 5 MVA",
            "Bus Duct Erection work",
            "LT Power Distribution work",
            "UG Cable Erection up to 630 Sq.mm",
            "Lighting and Control Wiring Work",
            "Energy Monitoring",
            "Internal Low Voltage Distribution work",
            "Earthing System & Lightning System",
            "All Types Tray Erection Work",
          ],
        },
      ],
    },
  },
  {
    id: "maintenance",
    icon: <TbSettingsCog />,
    title: "Maintenance",
    description: [
      "We provide comprehensive and non-comprehensive annual maintenance contract solutions, Electrical Maintenance services and Electrical Management services of Electrical Installations over a period of time (Annually, Half yearly, Quarterly, Monthly basis) to corporate companies, IT/BT sectors, Medical facilities, Multi-storied building, , Shopping malls, Multiplex etc.",
      "We are doing all kind of AMC in Electricals. Backed by a team of electrical professionals, we are offering a broad assortment of Industrial Electrical AMC Service for our customers. Offered services are executed under the direction of inspectors of quality. These are rendered according to the client’s demands and within the promised period of time.",
    ],
    quote: {
      show: false,
      item: [
        {
          title: "",
          desc: [],
          points: [],
        },
      ],
    },
  },
];

const Service = () => {
  let { selectedservice } = useParams();
  let service = services.find((s) => s.id === selectedservice);
  document.title = `Krico | ${service.title} Service`;

  return (
    <div className='text-slate-700 leading-8 w-full mb-8 bg-clip-padding backdrop-filter backdrop-blur-xl bg-opacity-50 p-12'>
      <h1 className='mb-4 py-10 text-2xl font-extrabold leading-none tracking-tight uppercase text-sky-400 lg:text-3xl grid place-items-center'>
        <div className='grid grid-cols-4 gap-4 leading-10'>
          <span className='flex justify-end text-red-400 text-9xl col-span-1'>
            {service.icon}
          </span>
          <span className='flex place-items-center col-span-3'>
            {service.title}
          </span>
        </div>
      </h1>
      <div className='mb-8'>
        {service.description.map((description, index) => (
          <div
            className='indent-16 pb-8 first-letter:text-2xl first-letter::text-lime-500'
            key={index}
          >
            {description}
          </div>
        ))}
      </div>
      {service.quote.show &&
        service.quote.item.map((item, id) => (
          <div className='m-4 mb-8 p-4' key={id}>
            <h1 className='my-8 text-xl capitalize font-semibold text-center'>
              {item.title}
            </h1>
            <div>
              {item.desc.map((description, index) => (
                <div
                  className='mb-4 first-letter:text-2xl text-center'
                  key={index}
                >
                  {description}
                </div>
              ))}
            </div>
            <div>
              {item.points.map((description, index) => (
                <div className='lg:m-auto lg:w-1/2' key={index}>
                  <div className='flex py-1 '>
                    <MdOutlineElectricBolt className='w-1/12 h-6 m-2 ' />
                    <div className='flex-1'>{description}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
    </div>
  );
};

export default Service;
