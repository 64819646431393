const Infrastructure = () => {
  const infrastructure = {
    title: "Infrastructure",
    items: [
      {
        title: "CNC SHEARING MACHINE – 4 x 3200 mm",
        img: [
          "https://www.rkeinfra.com/wp-content/uploads/2020/08/cutting-machine.jpg",
        ],
      },
      {
        title: "CNC PRESS BRAKE MACHINE – 80 T / 4000 mm",
        img: [
          "https://www.rkeinfra.com/wp-content/uploads/2020/08/bending-machine.jpg",
        ],
      },
      {
        title: "MIG & ARC Welding Unit",
        img: [
          "https://www.rkeinfra.com/wp-content/uploads/2020/08/welding-machine.jpg",
        ],
      },
      {
        title: "POWDER COATING UNIT",
        img: [
          "https://www.rkeinfra.com/wp-content/uploads/2020/08/powder-coating.jpg",
        ],
      },
    ],
  };

  document.title = `Krico | Infrastructure`;

  return (
    <div className='p-4 text-slate-800 leading-8 w-full'>
      <div className='mb-8 '>
        <h1 className='my-8 text-2xl font-extrabold leading-none tracking-tight uppercase text-red-800 lg:text-3xl grid place-items-center'>
          {infrastructure.title}
        </h1>

        <div className='grid  grid-cols-1  2xl:grid-cols-2 gap-8'>
          {infrastructure.items.map((item, i) => (
            <div className='flex' key={i}>
              <div className='h-96 shadow-2xl rounded-xl hover:shadow-lime-500 hover:scale-110 transition-all ease-linear duration-300 p-2 border-b-8 border-r-8 border-double border-lime-400 hover:border-0'>
                <img
                  src={item.img[0]}
                  alt={item.title}
                  className='w-full h-full object-fill rounded-xl '
                />
              </div>
              <div className='absolute border-t-2 border-l-2 border-red-900 text-slate-100 p-2 bg-red-800 rounded-tl-md scale-105'>
                {item.title}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Infrastructure;
